body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  p{
    margin: 0;
  }

  $darkGray:#201C1D;
  $lightGray:#EBEBEB;

  
  .header{
    height: 66px;
    width: 100%;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
    position: fixed;
    top: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    &__title{
        font-size: 24px;
        font-family: Futura;
    }
  }

  .titleBanner{
    width: 100%;
    height: 48px;
    background-color: $darkGray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 5px;
    margin-bottom: 8px;
    box-sizing: border-box;
    &__title{
      color: white;
      font-family: Futura;
      flex: 1,
    }

    button{
      margin-left: 8px;
    }
}


 .commentTextArea{
   width: 100%;
   height: 200px;
   border-radius: 5px;
   background-color: $lightGray;
   box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
   border: none;
   padding: 5px;
   margin-bottom: 16px;
   font-size: 16px;
 
   &:enabled{
    background-color: white;
   }
 
 }

 .searchColumnItem{
   margin-bottom: 16px;
   justify-content: space-between;
   flex-wrap: wrap;
 }

 .columnItem{
   display: flex;
   flex-direction: row;
   align-items: center;
 }

 input{
   border-radius: 25px;
   border: 1px solid #EBEBEB;
   padding: 12px 12px;
   padding-left: 12px;
   box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
   -webkit-appearance: none;
 }
.searchInput{
  width: 80%;
  margin-right: 12px;
  font-size: 16px;
}
.searchBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  font-weight: bold;
  margin-right: 5px;
}


 label{
   font-weight: bold;
   margin-right: 8px;
 }

.panel{
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  // margin-top: 120px;
  margin-top: 20px;
  max-width: 1280px;
  @media screen and (max-width: 800px){
    flex-direction: column;
  }
}

 .itemPanel{
   width: 45%;
   @media screen and (max-width: 800px){
     width: 100%;
  }
 }

 .buttonErea{
   display: flex;
   justify-content: space-between;
   width: 100%;
   margin-top: 16px;
   margin-bottom: 16px;
 }

 .sendBtn{
   border-radius: 30px;
   background-color: #F85C17;
   box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
   display: flex;
   align-items: center;
   font-weight: bold;
   border: none;
   width: 120px;
   height: 44px;
   display: flex;
   flex-direction: row;
   justify-content: center;
   &:active{
     scale: 0.8;
   }
   span{
     color: white;
     font-size: 16px;
   }
 }
 .skipBtn{
   input{
    border: 1px solid black;
    background-color: white;
    width: 88px;
    font-size: 16px;
    font-weight: bold;
   }
 }


 .searchResulttable{ 
   margin-top: 8px;
   display: flex;
   overflow: scroll;
   flex-direction: column;
   max-height: 86px;
 }

 .uidContainer{
   justify-content: space-between;
   width: 100%;
   margin-bottom: 8px;
 }

 .uidInput{
   width: 55%;
  &:disabled {
    background: gray;
    color: white;
  }
 }
 .uidSeachBtn{
   width: 100px;
   background-color: #201C1D;
   color: white;
   font-weight: bold;
 }

 .errorAlert{
   background-color: #FCCDCA;
   height: 44px;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 16px;
 }
 
.gridItemContainer{
  position: relative;
  img.icon{
    position: absolute;
    bottom: 10px;
    right: 5px;
    height: 32px;
    width: 32px;
  }
}


 .imageItems{
   display: flex;
   flex-wrap: wrap;
   width: 100%;
 }
 
 .pullDownContainer{
   display: flex;
   justify-content: center;
   margin-top: 24px;
 }

//  ============================================InputPost ============================================

.selectImages{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;

  img{
    margin: 0 2px;
    border-radius: 10px;
    width: 150px;
    height: 150px;
    // width: 250px !important;
    // height: 250px !important;
  }
  .disable{
    opacity: .5;
  }

  img.icon{
    position: absolute;
    bottom: 10px;
    right: 5px;
    height: 24px;
    width: 24px;
  }
}

.searchShopContainer {
  margin-top: 20px;
  margin-bottom: 40px;
}
.pricesTitle {
  margin-top: 10px;
}

.prices {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 20px;
  border: 1px solid black;
  padding: 8px;

  &__item {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-right: 16px;
    border: 1px solid black;
    padding: 0 4px;
    cursor: pointer;

    &--inactive {
      text-decoration: line-through;
      color: rgb(147, 128, 128);
    }
  }
}

.dialog {
  max-width: 100%;
  width: 80vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: 95%;
  }

  &__input {
    width: 100%;
  }

  &__inputSmall {
    width: 140px;
    margin-left: 16px;
  }
}

.count {
  margin-left: 8px;
}