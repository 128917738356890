body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  margin: 0;
}

.header {
  height: 66px;
  width: 100%;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 10000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.header__title {
  font-size: 24px;
  font-family: Futura;
}

.titleBanner {
  width: 100%;
  height: 48px;
  background-color: #201C1D;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 5px;
  margin-bottom: 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.titleBanner__title {
  color: white;
  font-family: Futura;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.titleBanner button {
  margin-left: 8px;
}

.commentTextArea {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  background-color: #EBEBEB;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  border: none;
  padding: 5px;
  margin-bottom: 16px;
  font-size: 16px;
}

.commentTextArea:enabled {
  background-color: white;
}

.searchColumnItem {
  margin-bottom: 16px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.columnItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

input {
  border-radius: 25px;
  border: 1px solid #EBEBEB;
  padding: 12px 12px;
  padding-left: 12px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
}

.searchInput {
  width: 80%;
  margin-right: 12px;
  font-size: 16px;
}

.searchBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: black;
  color: white;
  font-weight: bold;
  margin-right: 5px;
}

label {
  font-weight: bold;
  margin-right: 8px;
}

.panel {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 1280px;
}

@media screen and (max-width: 800px) {
  .panel {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.itemPanel {
  width: 45%;
}

@media screen and (max-width: 800px) {
  .itemPanel {
    width: 100%;
  }
}

.buttonErea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.sendBtn {
  border-radius: 30px;
  background-color: #F85C17;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: bold;
  border: none;
  width: 120px;
  height: 44px;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.sendBtn:active {
  scale: 0.8;
}

.sendBtn span {
  color: white;
  font-size: 16px;
}

.skipBtn input {
  border: 1px solid black;
  background-color: white;
  width: 88px;
  font-size: 16px;
  font-weight: bold;
}

.searchResulttable {
  margin-top: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 86px;
}

.uidContainer {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}

.uidInput {
  width: 55%;
}

.uidInput:disabled {
  background: gray;
  color: white;
}

.uidSeachBtn {
  width: 100px;
  background-color: #201C1D;
  color: white;
  font-weight: bold;
}

.errorAlert {
  background-color: #FCCDCA;
  height: 44px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 16px;
}

.gridItemContainer {
  position: relative;
}

.gridItemContainer img.icon {
  position: absolute;
  bottom: 10px;
  right: 5px;
  height: 32px;
  width: 32px;
}

.imageItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}

.pullDownContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 24px;
}

.selectImages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
}

.selectImages img {
  margin: 0 2px;
  border-radius: 10px;
  width: 150px;
  height: 150px;
}

.selectImages .disable {
  opacity: .5;
}

.selectImages img.icon {
  position: absolute;
  bottom: 10px;
  right: 5px;
  height: 24px;
  width: 24px;
}

.searchShopContainer {
  margin-top: 20px;
  margin-bottom: 40px;
}

.pricesTitle {
  margin-top: 10px;
}

.prices {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  margin-bottom: 20px;
  border: 1px solid black;
  padding: 8px;
}

.prices__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  margin-right: 16px;
  border: 1px solid black;
  padding: 0 4px;
  cursor: pointer;
}

.prices__item--inactive {
  text-decoration: line-through;
  color: #938080;
}

.dialog {
  max-width: 100%;
  width: 80vw;
  height: 40vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dialog__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 16px;
  width: 95%;
}

.dialog__input {
  width: 100%;
}

.dialog__inputSmall {
  width: 140px;
  margin-left: 16px;
}

.count {
  margin-left: 8px;
}
